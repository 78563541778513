import req from './request'

function http(url, data, method = "GET") {
 return req({
    url,
    data,
    method: method,
 })
}

function get(url, data = {}) {
 return http(url, data, 'GET')
};

function post(url,data){
 return http(url, data, 'POST') 
};

export  {
    // http,
    post,
    get
}
