
<template>
  <div id="app">
    <Layout/>
  </div>
</template>
<script>
import Layout from '@/Layout/layout.vue'
import Login from '@/views/login/login.vue'
export default {
  components: {Layout,Login},
  name:'App',
  data() {
    return {

    }
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: fit-content;
  color: #2c3e50;
  margin: 0;
  }
</style>