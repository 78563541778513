import { render, staticRenderFns } from "./home.vue?vue&type=template&id=23a2ed98&scoped=true"
import script from "@/views/home/home?vue&type=script&lang=js&external"
export * from "@/views/home/home?vue&type=script&lang=js&external"
import style0 from "@/views/home/home.css?vue&type=style&index=0&id=23a2ed98&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23a2ed98",
  null
  
)

export default component.exports