import md5 from 'js-md5';
import { setToken,getToken } from '@/utils/auth';
import axios from "axios";
import { loginAccount,checkToken } from '@/utils/API'

export default {
  data() {
    return {
      form: {
        name: '',
        password: '',
      },
      token: null,
      isLogin: false,
    };
  },

  methods: {
    async submitForm() {
      const user = this.form.name
      const passWord = md5(this.form.password).toUpperCase()
      const checkTokenRes = await checkToken(getToken()? getToken(): '') 
      if(checkTokenRes.data.code !== 200){
        const check = await loginAccount(user, passWord);
        console.log(check);
        let token = check.data.data.token;
        if (check.data.code == 200) {
          setToken(token);
          this.$router.push('/home');
          location.reload()
          console.log('登录成功');
          this.$toast('登录成功');
        }else{
          this.$toast('用户名或密码错误');
        }
      }else if(checkTokenRes.data.data.state == 1) {
        this.$router.push('/home');
        location.reload()
        console.log('登录成功');
        this.$toast('登录成功');
      }else {
        this.$router.push('/login')
        location.reload()
        this.$toast('登录验证过期');
      }
      /*const check = await loginAccount(user, passWord);
      console.log(check);
      let token = check.data.data.token;
      if (check.data.code == 200) {
        setToken(check.data.data.token);
        //const checker  = await checkToken(token)
        //const checker = await axios.post("/api/admin/login/checkToken", { token: token })
        if (checker.data.data.state == 1) {
          this.$router.push('/home');
          location.reload()
          console.log('登录成功');
          this.$toast('登录成功');
        }
        else {
          this.$router.push('/login')
          location.reload()
          this.$toast('登录验证过期');
        }
      }
      else {
        this.$toast('用户名或密码错误');
      }*/
    }
  },
}
