import Vue from 'vue'
import App from './App.vue'
import Vant from 'vant';
import 'vant/lib/index.css';
import router from './router/routes'
import axios from 'axios'
import VueLazyload from 'vue-lazyload'
// import  apis from '@/utils/API'

import { List } from 'vant';
Vue.use(List)

// Vue.use(VueRouter)
Vue.use(Vant);
Vue.config.productionTip = false;
Vue.prototype.$axios = axios;


router.beforeEach((to, from, next) => {
  // if (to.meta.title) {
  //   document.title = to.meta.title;
  // } else {
    document.title = '递达递';
  // }
  next();
});

new Vue({
  el: '#app',
  router,
  render: h => h(App),
}).$mount('#app')
