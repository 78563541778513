
import Login from '@/views/login/login.vue'
import { getToken } from '@/utils/auth'
import home from '@/views/home/home.vue'


export default {
  components:{ Login },
  async mounted() {
    const token =  getToken()
    if(token){
      console.log('token exist!');
       if (this.$route.path == '/login') {
        this.isLogin = false
      }
      else {
        this.isLogin = true
      }
    }else if(this.$route.path !== '/login'){
      this.$router.push('/login');
      location.reload()
      this.$toast('登录已过期');
      this.isLogin = false
    }
  },
  data() { 
    const routeData = [
      {
        path: '/home',
        name: 'home',
        component: home,
        meta: {
          title:'总览'
        } 
    },
    {
        path: '/undertake',
        name: 'undertake',
        meta: {
          title:'待办'
        },
        component: () => import('@/views/undertake/undertake.vue')
  
    },
    {
        path: '/message',
        name: 'message',
        meta: {
          title:'消息'
        },
        component: () => import('@/views/message/message.vue')
    },
    {
        path: '/orders',
        name: 'orders',
        meta: {
          title:'订单'
        },
        component: () => import('@/views/orders/orders.vue')
    },
    ]


    return {
      //true为显示tabbar栏，false为隐藏
      isTabShow: true,
      titleShow:true,
      currentTitle:'',
      routeData,
      isLogin:false
    }
  },
  created() {
    this.updateTitle();
  },
  //监听路由变化，显示或隐藏tabbar栏
  watch: {
    "$route": function (to) {
      var tabArr = ["/home", "/undertake", "/message", "/orders"];
      if (tabArr.includes(to.path)) {
        this.isTabShow = true   
      } else {
        this.isTabShow = false, 
             this.titleShow = false
      }
    },
    '$route': 'updateTitle'
  },
  methods: {
    updateTitle() {
      this.currentTitle = this.$route.meta.title;
    },
    back() {
      const currentRoute = this.$route;
      const history = this.$router.history;
    
       if (history.length > 1) {
        // 如果当前是登录页并且历史堆栈中有多个记录
        // 则检查上一个页面是否是登录页
        const previousRoute = history[history.length - 2];
        
        // 如果上一个页面不是登录页，则返回到上一个页面
        if (previousRoute.path !== '/login') {
          this.$router.go(-1);
        } else {
          // 如果上一个页面也是登录页，则重定向到主页或其他页面
          this.$router.push('/home');
        }
      } else {
        // 如果历史堆栈中只有一个记录（即登录页），则重定向到主页或其他页面
        this.$router.push('/home');
      }
    }
  }
}