import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/home.vue'
import Login from '@/views/login/login.vue'
 
Vue.use(VueRouter)
 
const routes = [
   {
       path: '/',
       redirect: "/login",
    },
   {
       path: '/login',
       name: 'login',
       component: Login,
   },
    {
        path: '/home',
        name: 'home',
        component: Home,
        meta: {
          title:'总览'
        } 
    },
    {
        path: '/undertake',
        name: 'undertake',
        meta: {
          title:'待办'
        },
        component: () => import('@/views/undertake/undertake.vue')
  
    },
    //WIP
    /*{
        path: '/message',
        name: 'message',
        meta: {
          title:'消息'
        },
        component: () => import('@/views/message/message.vue')
    },*/
    {
        path: '/orders',
        name: 'orders',
        meta: {
          title:'订单'
        },
        component: () => import('@/views/orders/orders.vue')
    },
 
]
 

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
const VueRouterPush = router.push
router.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

router.beforeEach((to, from, next) => {
  if (router.currentRoute.path === to.path) {
    // 当前路由与目标路由相同，取消跳转
    next(false);
  } else {
    // 继续正常的路由导航
    next();
  }
});
// router.beforeEach((to,from,next)=>{
//   console.log(to);
// })

export default router
