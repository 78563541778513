import { get, post } from "./http";

// export default {
export function loginAccount(username, password) {
  const data = {
    username: username,
    password: password,
  };
  return post("/login/validate", data);
};

export function checkToken(token){
  return post("/login/checkToken",{token})
}

export function orders(current, pageSize, trackId = null) {
  const data = {
    current:current,
    pageSize:pageSize,
    trackId
  };
  return post("order/list",data)
};


export function orderTrace(trackId) {
  const data = {
    trackId:trackId
  }
  return post("/order/trace",data)
};


export function messages(current, pageSize) {
  const data = {
    current:current,
    pageSize:pageSize
  };
  return post("/msg/get",data)
};


export function updates(id, status, processDesc) {
  const data = {
    id:id,
    status:status,
    processDesc:processDesc
  };
  return post("/msg/update",data)
};


export function refund(trackId, force) {
  const data = {
    trackId:trackId,
    force:force
  };
  return post("/order/refund",data)
};


export function returnCoupon(trackId, couponId) {
  const data = {
    trackId:trackId,
    couponId:couponId,
    force: 1
  };
  return post("/order/returnCoupon",data)
}


export function formList(current, pageSize) {
  const data = {
    current:current,
    pageSize:pageSize,
    status:0
  };
  return post("/approval/list",data)
}


export function formProcess(trackId, remark, status) {
  const data = {
    trackId:trackId,
    remark:remark,
    status:status
  };
  return post("/approval/process",data)
}
