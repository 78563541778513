import axios from 'axios'

export function getToken(){
    // console.log(localStorage.getItem("token"))
    //return JSON.parse(localStorage.getItem("token"));
    return localStorage.getItem("token");
}

export function setToken(data) {
    return localStorage.setItem('token',data)
}

export function getRefreshToken(){
    // return JSON.parse(localStorage.getItem("refreshToken"));
    return localStorage.getItem("refreshToken")
}

export function setRefreshToken(refresh){
    return localStorage.setItem("refreshToken",refresh)
}

export function clearToken(){
    localStorage.removeItem("token");
}