import axios from "axios";
import { getToken } from "./auth";
// import router from "@/router/routes";

const api = axios.create({
  //baseURL: 'https://b01.proxy.vyitec.com/admin'
  baseURL: 'https://v2.edida.cn/admin',
});
//请求拦截器
api.interceptors.request.use(
  (config) => {
    // console.log(config);
    config.headers = {
      "Content-Type": "application/x-www-form-urlencoded", //配置请求头
    };
    // console.log(config);
    const userToken = getToken();
    config.headers["token"] = userToken;
    // console.log("token存在");
    // console.log(userToken, config);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
// 响应拦截器
api.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      return response;
    } else {
      window.location = "/login"
    }
  },
  (error) => {
    if (error && error.response) {
      // 1.公共错误处理
      // 2.根据响应码具体处理
      switch (error.response.status) {
        case 400:
          error.message = "错误请求";
          break;
        case 401:
          error.message = "未授权，请重新登录";
          break;
        case 403:
          error.message = "拒绝访问";
          break;
        case 404:
          error.message = "请求错误,未找到该资源";
          window.location.href = "/NotFound";
          break;
        case 405:
          error.message = "请求方法未允许";
          break;
        case 408:
          error.message = "请求超时";
          break;
        case 500:
          error.message = "服务器端出错";
          break;
        case 501:
          error.message = "网络未实现";
          break;
        case 502:
          error.message = "网络错误";
          break;
        case 503:
          error.message = "服务不可用";
          break;
        case 504:
          error.message = "网络超时";
          break;
        case 505:
          error.message = "http版本不支持该请求";
          break;
        default:
          error.message = `连接错误${error.response.status}`;
      }
    } else {
      // 超时处理
      if (JSON.stringify(error).includes("timeout")) {
        Message.error("服务器响应超时，请刷新当前页");
      }
      return Promise.reject(error);
    }
  }
);
export default api;
